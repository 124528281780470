<script setup>
import {onMounted, ref} from "vue";

const siteUrl = document.querySelector('meta[name="site-url"]').content;

// 一度に取得する本の数
const NUMBER = 4;

// 現在表示してる本のリスト
const books = ref([]);

// 現在のページ
const phase = ref(1);

// 次のページがあるかどうか
const isNext = ref(true);

const more = async () => {
  try {
    // 取得を試みる
    const response = await fetch(siteUrl+'book/trends?number='+NUMBER+'&page='+phase.value);

    // JSON パースを試みる
    const data = await response.json();

    // 追加
    books.value = books.value.concat(data.books);

    // 次のページがあるかどうか
    isNext.value = data.next;

    // ページを進める
    phase.value++;
  }

    // 特に何もしない(例外で処理を中断するために存在)
  catch (e) {
    console.error(e);
  }
};

// 最初の一ページ目を表示
onMounted(more);
</script>

<template>
  <div
    v-if="books.length > 0 || isNext"
    class="gbSideOuter pBackColorGray4"
  >
    <div class="gbSideInner">
      <section class="lyPd16">
        <h4 class="lyPt8 lyPb8 lyPr4 lyPl4 pFont16 pFontBold pBorderBottom2pxSolidBlack lyMb16">話題の書籍一覧</h4>
        <ul class="lyMb16">
          <li
            v-for="book in books"
            :key="book.id"
            class="lyMb8"
          >
            <div class="lyVerticalStartBreak lyFlexItemBreakBan lyPd8 pBackColorWhite pBorderRadius2px">
              <div class="lyHorizontal lyHorizontalRev lyW100">
                <div class="lyPd8 lyW100">
                  <p class="pFont14 lyMb8">
                    <a
                      :href="siteUrl+'book/'+book.id"
                      class="pFontBold pFontBlack"
                    >{{book.title}}</a>
                  </p>
                  <dl class="lyMb8 pFont14">
                    <div
                      v-for="author in book.authors"
                      :key="author.id"
                      class="lyHorizontal lyMb6"
                    >
                      <dt class="lyShrinkNone pFontMedium lyW70px">{{author.pivot.position === '' ? '著者名' : author.pivot.position}}</dt>
                      <dd>
                        <div class="lyHorizontal">
                          <p class="lyW24px lyMr8 lyShrinkNone lyOverFlewHidden pBorderRadiusEllipse">
                            <img
                              :src="author.thumbnail !== '' && author.thumbnail !== null ? siteUrl+'public_image/'+author.thumbnail+'?size=writer' : siteUrl+'images/author/authorDefault@1x.png'"
                              loading="lazy"
                              alt=""
                            >
                          </p>
                          <p class="pFont14">
                            <a
                              :href="siteUrl+'author/'+author.id"
                              class="pFontMedium"
                            >{{author.name}}</a>
                          </p>
                        </div>
                      </dd>
                    </div>
                    <div class="lyHorizontal lyMb6">
                      <dt class="pFontMedium lyW70px">出版社</dt>
                      <dd>{{book.publisher}}</dd>
                    </div>
                  </dl>
                </div>
                <p class="lyShrinkNone lyPd8">
                  <a
                    :href="siteUrl+'book/'+book.id"
                    class="lyBlock pImageDropShadow lyImageFitContain"
                    style="max-width: 120px;"
                  >
                    <img
                      :src="siteUrl+'public_image/'+book.thumbnail"
                      loading="lazy"
                      alt=""
                      style="width:100px"
                    >
                  </a>
                </p>
              </div>
            </div>
          </li>
        </ul>
        <p v-if="isNext">
          <button
            class="lyHvCnt lyW100 lyPt12 lyPb12 lyTextCnt pFont14 pFontBold pFontBlack pBackColorGray4 pBorder1pxSolidBlack pBorderRadius2px"
            @click="more"
          >
            もっと見る
            <span class="lyBlock lyW16px lyMl8">
									<img
                    :src="siteUrl+'images/btnIconRightArrowBlack.svg'"
                    width="16"
                    height="16"
                    loading="lazy"
                    alt=""
                  >
								</span>
          </button>
        </p>
      </section>
    </div>
  </div>
</template>
